<template>
  <div class="f-je w-100p m-tx4">
    <input class="input-text w-x80 m-rx4" placeholder="Default names (separated by comma)" v-model="defaultNameString">
    <button :class="`button ${buttonClasses}`" @click="addReceipts">Add Receipts</button>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useBillSplitterStore } from '../../../stores/bill_splitter_store';
  import { useModalStore } from '../../../stores/modal_store';

  const billSplitterStore = useBillSplitterStore();
  const modalStore = useModalStore();

  // Names
  const defaultNameString = ref('');
  const defaultNames = computed(() => defaultNameString.value.split(',').map(n => n.trim()).filter(n => n));

  // Receipts
  const { receipts } = defineProps(['receipts']);
  const validReceipts = computed(() => receipts.filter(r => r));
  const buttonClasses = computed(() => validReceipts.value.length > 0 ? '' : 'disabled');
  const addReceipts = () => {
    validReceipts.value.forEach((receipt) => {
      billSplitterStore.addTab({ ...receipt, names: defaultNames.value });
    });
    billSplitterStore.removeEmptyTabs();
    modalStore.closeModal();
  };
</script>
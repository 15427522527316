import { defineStore } from "pinia";

export const useBillSplitterStore = defineStore("BillSplitterStore", {
  state: () => {
    return {
      selectedTabIdx: 0,
      tabs: [
        {
          title: '',
          people: [],
          items: [],
          totalSpent: 0,
        },
      ],
    };
  },

  getters: {
    selectedTab: (state) => {
      return state.tabs[state.selectedTabIdx];
    },

    currencyFormat: () => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },

    totalForPerson: (state) => {
      return (personIdx) => {
        // For whatever reason I can't seem to access getters in here,
        // thus the repeated "selectedTab" definition below
        const items = state.tabs[state.selectedTabIdx].people[personIdx].items;
        let total = 0;
        items.forEach((i) => total += i.price * i.perc);
        return total;
      }
    },
  },

  actions: {
    selectTab(tabIdx) {
      this.selectedTabIdx = tabIdx;
    },

    addTab(args) {
      const title = args?.title || '';
      const items = args?.items || [];
      const totalSpent = args?.totalSpent || 0;
      const people = args?.names?.map((name) => {
        return { name, items: [] };
      }) || [];
      this.tabs.push({ title, items, totalSpent, people });
      this.selectedTabIdx = this.tabs.length - 1;
    },

    removeTab(tabIdx) {
      const newTabs = [...this.tabs];
      newTabs.splice(tabIdx, 1);
      this.tabs = newTabs;
      // Account for closing a selectedTab that is the last one
      if (!this.selectedTab) this.selectedTabIdx = this.tabs.length - 1;
    },

    removeEmptyTabs() {
      this.tabs.forEach((tab, tabIdx) => {
        if (!tab.title && !tab.people.length && !tab.items.length && !tab.totalSpent) this.removeTab(tabIdx);
      });
    },

    addPerson(name) {
      this.selectedTab.people.push({ name, items: [] });
    },

    removePerson(personIdx) {
      const newPeople = [...this.selectedTab.people];
      newPeople.splice(personIdx, 1);
      this.selectedTab.people = newPeople;
    },

    addItemForPerson(personIdx, item) {
      this.selectedTab.people[personIdx].items.push(item);
    },

    removeItemForPerson(personIdx, itemIdx) {
      const newItems = [...this.selectedTab.people[personIdx].items];
      newItems.splice(itemIdx, 1);
      this.selectedTab.people[personIdx].items = newItems;
    },

    addItem(name, price) {
      this.selectedTab.items.push({ name, price });
    },

    removeItem(itemIdx) {
      const newItems = [...this.selectedTab.items];
      newItems.splice(itemIdx, 1);
      this.selectedTab.items = newItems;
    },

    updateTabValue({ key, value }) {
      this.selectedTab[key] = value;
    },
  },
});